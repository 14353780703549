<template>
    <div v-infinite-scroll="loadMore" infinite-scroll-distance="25">
        <div v-if="isFetching" class="l-padded">
            <VSpinner size="medium" :line-fg-color="spinnerColor" :speed="1" />
        </div>

        <div
            v-else-if="!list.length"
            class="l-padded l-inline l-center t-subtle t-small"
        >
            <span>
                {{ $t('shared.noSearchResult') }}
            </span>
        </div>

        <template v-else>
            <div class="l-inline l-spread l-center-v l-gap-2 filter-actions">
                <div class="l-inline l-center-v l-gap-2">
                    <i18n path="showOf">
                        <b>{{ listFiltered.length }}</b>
                        <b>{{ list.length }}</b>
                    </i18n>

                    <a
                        v-if="
                            filterDriver ||
                                filterTracker.length ||
                                filterProcessed
                        "
                        @click="$emit('clearFilter')"
                    >
                        {{ $t('clearFilter') }}
                    </a>
                </div>

                <div class="l-inline l-center-v l-gap-2">
                    <label
                        :title="
                            selected.length
                                ? $t('markAs', [
                                      isAllProcessed
                                          ? $t('processedNot')
                                          : $t('processed'),
                                  ])
                                : null
                        "
                    >
                        <ToggleButton
                            redesigned
                            :disabled="!selected.length"
                            :value="isAllProcessed"
                            @input="toggleSelected"
                        />
                    </label>

                    <IconButton
                        v-if="isDownloading"
                        :title="$t('downloadStop')"
                        @click="handleDownloadStop"
                    >
                        <FileRemoveIcon width="18" height="18" />
                    </IconButton>

                    <IconButton
                        v-else
                        :disabled="!selected.length"
                        :title="$t('download')"
                        @click="handleDownload"
                    >
                        <FileDownloadIcon width="18" height="18" />
                    </IconButton>
                </div>
            </div>

            <table>
                <thead>
                    <tr>
                        <th>
                            <input
                                type="checkbox"
                                class="clickable"
                                @change="selectAll"
                            />
                        </th>

                        <th>
                            {{ $t('driver') }}

                            <IconButton @click="handleSort('driverName')">
                                <SortArrowIcon
                                    :direction="
                                        sortBy === 'driverName'
                                            ? sortDirection
                                            : 0
                                    "
                                    width="10"
                                    height="10"
                                />
                            </IconButton>
                        </th>

                        <th>
                            {{ $t('shared.asset') }}

                            <IconButton @click="handleSort('assetName')">
                                <SortArrowIcon
                                    :direction="
                                        sortBy === 'assetName'
                                            ? sortDirection
                                            : 0
                                    "
                                    width="10"
                                    height="10"
                                />
                            </IconButton>
                        </th>

                        <th>
                            {{ $t('createdAt') }}

                            <IconButton
                                @click="handleSort('timestamp_recorded')"
                            >
                                <SortArrowIcon
                                    :direction="
                                        sortBy === 'timestamp_recorded'
                                            ? sortDirection
                                            : 0
                                    "
                                    width="10"
                                    height="10"
                                />
                            </IconButton>
                        </th>

                        <th>
                            {{ $t('download') }}
                        </th>

                        <th>
                            {{ $t('processed') }}
                        </th>
                    </tr>
                </thead>

                <template v-for="item in listPortion">
                    <tr :key="item.id">
                        <td>
                            <input
                                v-model="selected"
                                type="checkbox"
                                class="clickable"
                                :value="item.id"
                            />
                        </td>

                        <td>
                            {{ item.driverName || '–' }}
                        </td>

                        <td>
                            {{ item.assetName || '–' }}
                        </td>

                        <td>
                            {{ item.timestampFormatted }}
                        </td>

                        <td>
                            <a
                                v-if="item.file_url"
                                :href="item.file_url"
                                download
                                :title="$t('download')"
                            >
                                <IconButton>
                                    <DownloadIcon />
                                </IconButton>
                            </a>
                        </td>
                        <td>
                            <ToggleButton
                                redesigned
                                :value="item.processed"
                                @input="toggle(item)"
                            />
                        </td>
                    </tr>
                </template>
            </table>
        </template>
    </div>
</template>

<script>
import infiniteScroll from 'vue-infinite-scroll'
import moment from 'moment-timezone'
import VSpinner from 'vue-simple-spinner'

import { domHelper, httpHelper } from '@/utils'
import DownloadIcon from '@/components/icons/DownloadIcon'
import FileDownloadIcon from '@/components/icons/FileDownloadIcon'
import FileRemoveIcon from '@/components/icons/FileRemoveIcon'
import IconButton from '@/components/IconButton'
import SortArrowIcon from '@/components/icons/SortArrowIcon'
import ToggleButton from '@/components/ToggleButton'

const defaultScrollLimit = 15
const defaultSortColumn = 'timestamp_recorded'
const defaultSortDirection = 1

export default {
    name: 'TachographHistoryTable',
    components: {
        DownloadIcon,
        FileDownloadIcon,
        FileRemoveIcon,
        IconButton,
        SortArrowIcon,
        ToggleButton,
        VSpinner,
    },
    directives: {
        infiniteScroll,
    },
    props: {
        filterDate: {
            type: Array,
            default: null,
        },
        filterDriver: {
            type: String,
            default: '',
        },
        filterTracker: {
            type: Array,
            default: () => [],
        },
        filterProcessed: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            isDownloading: false,
            isFetching: false,
            list: [],
            scrollLimit: defaultScrollLimit,
            scrollStep: defaultScrollLimit,
            selected: [],
            sortBy: defaultSortColumn,
            sortDirection: defaultSortDirection,
            spinnerColor: process.env.VUE_APP_COLOR_PRIMARY,
        }
    },
    computed: {
        isAllProcessed() {
            return !!(
                this.selected.length &&
                this.selected.every(
                    id => this.list.find(item => item.id === id).processed
                )
            )
        },
        listFiltered() {
            if (this.sortBy) {
                return this.list.slice().sort((a, b) => {
                    const aValue = a[this.sortBy]
                    const bValue = b[this.sortBy]

                    if (this.sortBy === 'timestamp_recorded') {
                        return (
                            this.sortDirection *
                            (new Date(bValue).getTime() -
                                new Date(aValue).getTime())
                        )
                    } else if (aValue === bValue) {
                        return 0
                    } else if (aValue == null) {
                        return 1
                    } else if (bValue == null) {
                        return -1
                    } else if (typeof aValue === 'string') {
                        return aValue.localeCompare(bValue) * this.sortDirection
                    }

                    return aValue > bValue
                        ? this.sortDirection
                        : -this.sortDirection
                })
            }

            return this.list
        },
        listPortion() {
            return this.listFiltered.length === this.list.length
                ? this.listFiltered.slice(0, this.scrollLimit)
                : this.listFiltered
        },
    },
    watch: {
        filterDate() {
            this.fetchTachographHistory()
        },
        filterDriver() {
            this.fetchTachographHistory()
        },
        filterProcessed() {
            this.fetchTachographHistory()
        },
        filterTracker() {
            this.fetchTachographHistory()
        },
    },
    mounted() {
        this.fetchTachographHistory()
    },
    beforeDestroy() {
        this.handleDownloadStop()
    },
    methods: {
        async toggle(selectedItem) {
            try {
                const { data } = await httpHelper.put(
                    `/tachograph/files/${selectedItem.id}/`,
                    {
                        processed: !selectedItem.processed,
                    }
                )
                selectedItem.processed = data.processed
            } catch {
                this.$notify({
                    title: this.$t('putRequestErrorTitle'),
                    text: this.$t('putRequestErrorText'),
                    group: 'center',
                })
            }
        },
        toggleSelected() {
            try {
                const payload = { processed: !this.isAllProcessed }
                this.selected.forEach(async id => {
                    const listItem = this.list.find(item => item.id === id)
                    if (listItem?.processed !== payload.processed) {
                        const { data } = await httpHelper.put(
                            `/tachograph/files/${id}/`,
                            payload
                        )
                        listItem.processed = data.processed
                    }
                })
            } catch {
                this.$notify({
                    title: this.$t('putRequestErrorTitle'),
                    text: this.$t('putRequestErrorText'),
                    group: 'center',
                })
            }
        },
        async fetchTachographHistory() {
            this.isFetching = true
            const { data } = await httpHelper.getRecursively(
                '/tachograph/files/',
                {
                    params: {
                        limit: process.env.VUE_APP_LIMIT_RECORDS_PER_REQUEST,
                        start: this.filterDate[0],
                        end: this.filterDate[1],
                        asset: this.filterTracker.map(tracker => tracker.asset),
                        driver: this.filterDriver,
                        ...(this.filterProcessed && {
                            processed: this.filterProcessed.value ? 2 : 3,
                        }),
                    },
                }
            )
            this.list = data.results.map(item => ({
                ...item,
                assetName: item.asset?.name,
                driverName: item.driver?.name,
                timestampFormatted: moment(item.timestamp_recorded).format(
                    'DD.MM.YYYY HH:mm'
                ),
            }))
            this.isFetching = false
        },
        handleDownload() {
            this.isDownloading = true
            this.handleDownloadRecursively(
                this.list.filter(item => this.selected.includes(item.id))
            )
        },
        handleDownloadRecursively(items, i = 0) {
            if (!this.isDownloading) {
                return
            }
            domHelper.downloadDataUrl(
                items[i].file_url,
                items[i].file_url
                    .split('/')
                    .pop()
                    .split('?')[0]
            )
            if (i >= items.length - 1) {
                this.isDownloading = false
            } else {
                setTimeout(() => {
                    this.handleDownloadRecursively(items, i + 1)
                }, 1000)
            }
        },
        handleDownloadStop() {
            this.isDownloading = false
        },
        handleSort(column) {
            if (this.sortBy !== column || this.sortDirection !== 1) {
                this.sortBy = column
                this.sortDirection = 1
            } else if (this.sortDirection === 1) {
                this.sortDirection = -1
            }
        },
        loadMore() {
            if (this.scrollLimit < this.list.length) {
                this.scrollLimit += this.scrollStep
            }
        },
        selectAll(event) {
            this.selected = event.target.checked
                ? this.listFiltered.map(item => item.id)
                : []
        },
    },
}
</script>

<i18n>
{
    "en": {
        "clearFilter": "Clear filter",
        "createdAt": "Created at",
        "download": "Download",
        "downloadStop": "Stop downloading",
        "driver": "Driver",
        "markAs": "Mark the selection as “{0}”",
        "processed": "Processed",
        "processedNot": "Not Processed",
        "putRequestErrorText": "Entry could not be updated",
        "putRequestErrorTitle": "Error",
        "showOf": "Show {0} of {1}"
    },
    "de": {
        "clearFilter": "Filter zurücksetzen",
        "createdAt": "Erstellt am",
        "download": "Herunterladen",
        "downloadStop": "Herunterladen beenden",
        "driver": "Fahrer",
        "markAs": "Markieren Sie die Auswahl als „{0}“",
        "processed": "Verarbeitet",
        "processedNot": "Nicht verarbeitet",
        "putRequestErrorText": "Eintrag konnte nicht aktualisiert werden",
        "putRequestErrorTitle": "Fehler",
        "showOf": "Zeige {0} von {1}"
    },
    "fr": {
        "clearFilter": "Supprimer le filtre",
        "createdAt": "Créé à",
        "download": "Télécharger",
        "downloadStop": "Arrêter le téléchargement",
        "driver": "Conducteur",
        "markAs": "Marquer la sélection comme «{0}»",
        "processed": "Traité",
        "processedNot": "Pas traité",
        "putRequestErrorText": "L'entrée n'a pas pu être mise à jour",
        "putRequestErrorTitle": "Erreur",
        "showOf": "Afficher {0} de {1}"
    },
    "it": {
        "clearFilter": "Annullare il filtro",
        "createdAt": "Creato a",
        "download": "Scaricare",
        "downloadStop": "Smettere di scaricare",
        "driver": "Conducente",
        "markAs": "Contrassegnare la selezione come “{0}”",
        "processed": "Trattare",
        "processedNot": "Non Trattare",
        "putRequestErrorText": "Non è stato possibile aggiornare la voce",
        "putRequestErrorTitle": "Errore",
        "showOf": "Listo {0} di {1}"
    }
}
</i18n>

<style lang="scss" scoped>
.filter-actions {
    position: sticky;
    top: 0;
    left: 0;
    padding: 1rem 2rem;
    height: 52px;
    border-bottom: $style-border;
    background-color: #fff;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.7);
    z-index: 10;

    a {
        font-size: 13px;
        color: $color-text-new;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
            text-decoration: none;
        }
    }
}

table {
    display: block;
    width: 100%;
    border-spacing: 0;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.7);

    thead {
        position: sticky;
        top: 52px;
        background-color: #fff;
        z-index: 10;

        .icon-button {
            margin-left: 5px;
        }
    }

    tr {
        &:not(:first-of-type) {
            td {
                border-top: $style-border;
            }
        }

        th {
            text-align: left;
            border-bottom: $style-border;
        }

        th,
        td {
            padding: 1rem;
            white-space: nowrap;

            &:first-child {
                padding-left: 2rem;
            }

            &:last-child {
                padding-right: 2rem;
            }

            &:not(:last-child) {
                width: 1%;
            }
        }
    }

    a {
        display: block;

        .icon-button {
            display: block;
        }
    }
}
</style>
