var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadMore),expression:"loadMore"}],attrs:{"infinite-scroll-distance":"25"}},[(_vm.isFetching)?_c('div',{staticClass:"l-padded"},[_c('VSpinner',{attrs:{"size":"medium","line-fg-color":_vm.spinnerColor,"speed":1}})],1):(!_vm.list.length)?_c('div',{staticClass:"l-padded l-inline l-center t-subtle t-small"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('shared.noSearchResult'))+" ")])]):[_c('div',{staticClass:"l-inline l-spread l-center-v l-gap-2 filter-actions"},[_c('div',{staticClass:"l-inline l-center-v l-gap-2"},[_c('i18n',{attrs:{"path":"showOf"}},[_c('b',[_vm._v(_vm._s(_vm.listFiltered.length))]),_c('b',[_vm._v(_vm._s(_vm.list.length))])]),(
                        _vm.filterDriver ||
                            _vm.filterTracker.length ||
                            _vm.filterProcessed
                    )?_c('a',{on:{"click":function($event){return _vm.$emit('clearFilter')}}},[_vm._v(" "+_vm._s(_vm.$t('clearFilter'))+" ")]):_vm._e()],1),_c('div',{staticClass:"l-inline l-center-v l-gap-2"},[_c('label',{attrs:{"title":_vm.selected.length
                            ? _vm.$t('markAs', [
                                  _vm.isAllProcessed
                                      ? _vm.$t('processedNot')
                                      : _vm.$t('processed') ])
                            : null}},[_c('ToggleButton',{attrs:{"redesigned":"","disabled":!_vm.selected.length,"value":_vm.isAllProcessed},on:{"input":_vm.toggleSelected}})],1),(_vm.isDownloading)?_c('IconButton',{attrs:{"title":_vm.$t('downloadStop')},on:{"click":_vm.handleDownloadStop}},[_c('FileRemoveIcon',{attrs:{"width":"18","height":"18"}})],1):_c('IconButton',{attrs:{"disabled":!_vm.selected.length,"title":_vm.$t('download')},on:{"click":_vm.handleDownload}},[_c('FileDownloadIcon',{attrs:{"width":"18","height":"18"}})],1)],1)]),_c('table',[_c('thead',[_c('tr',[_c('th',[_c('input',{staticClass:"clickable",attrs:{"type":"checkbox"},on:{"change":_vm.selectAll}})]),_c('th',[_vm._v(" "+_vm._s(_vm.$t('driver'))+" "),_c('IconButton',{on:{"click":function($event){return _vm.handleSort('driverName')}}},[_c('SortArrowIcon',{attrs:{"direction":_vm.sortBy === 'driverName'
                                        ? _vm.sortDirection
                                        : 0,"width":"10","height":"10"}})],1)],1),_c('th',[_vm._v(" "+_vm._s(_vm.$t('shared.asset'))+" "),_c('IconButton',{on:{"click":function($event){return _vm.handleSort('assetName')}}},[_c('SortArrowIcon',{attrs:{"direction":_vm.sortBy === 'assetName'
                                        ? _vm.sortDirection
                                        : 0,"width":"10","height":"10"}})],1)],1),_c('th',[_vm._v(" "+_vm._s(_vm.$t('createdAt'))+" "),_c('IconButton',{on:{"click":function($event){return _vm.handleSort('timestamp_recorded')}}},[_c('SortArrowIcon',{attrs:{"direction":_vm.sortBy === 'timestamp_recorded'
                                        ? _vm.sortDirection
                                        : 0,"width":"10","height":"10"}})],1)],1),_c('th',[_vm._v(" "+_vm._s(_vm.$t('download'))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t('processed'))+" ")])])]),_vm._l((_vm.listPortion),function(item){return [_c('tr',{key:item.id},[_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected),expression:"selected"}],staticClass:"clickable",attrs:{"type":"checkbox"},domProps:{"value":item.id,"checked":Array.isArray(_vm.selected)?_vm._i(_vm.selected,item.id)>-1:(_vm.selected)},on:{"change":function($event){var $$a=_vm.selected,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=item.id,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selected=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selected=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selected=$$c}}}})]),_c('td',[_vm._v(" "+_vm._s(item.driverName || '–')+" ")]),_c('td',[_vm._v(" "+_vm._s(item.assetName || '–')+" ")]),_c('td',[_vm._v(" "+_vm._s(item.timestampFormatted)+" ")]),_c('td',[(item.file_url)?_c('a',{attrs:{"href":item.file_url,"download":"","title":_vm.$t('download')}},[_c('IconButton',[_c('DownloadIcon')],1)],1):_vm._e()]),_c('td',[_c('ToggleButton',{attrs:{"redesigned":"","value":item.processed},on:{"input":function($event){return _vm.toggle(item)}}})],1)])]})],2)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }